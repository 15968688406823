import React from "react";
import { reduxForm, Field } from "redux-form";
import PropTypes from "prop-types";

import Module from "../../../../components/Module";
import ButtonsWrapper from "../../../../components/ButtonsWrapper";
import Button from "../../../../components/Button";
import MessageWarning from "../../../../components/MessageWarning";
import MessageInformation from "../../../../components/MessageInformation";
import CheckboxWrapper from "../../../../components/CheckboxWrapper";
import Checkbox from "../../../../components/Checkbox";
import Upload from "../../../../components/Upload";
import ShowHideArea from "../../../../components/ShowHideArea";
import FormWrapper from "../../../../components/FormWrapper";
import { displayErrorMessage } from "../../../../utils";
import { JUSTIFICATION_OPTIONS } from "../../../../constants";

import style from "./style.scss";

// A function to show different messages to user based on mandatory fields
/*export const showMessage = ippPriceDifference => {
  const { percentageAboveIpp, amountAboveIpp, isAboveIpp } = ippPriceDifference;
  return isAboveIpp ? (
    <MessageWarning>
      The most cost-effective option has not been chosen. Purchase is{" "}
      <span className={style.valuesAboveIpp}>{percentageAboveIpp}%</span> {" above IPP "}
      <span className={style.valuesAboveIpp}>(US$ {amountAboveIpp})</span>.
    </MessageWarning>
  ) : (
    <MessageInformation>
      The cost-effective solution has been chosen. There is no need for justification.
    </MessageInformation>
  );
};*/

export const showMessage = (ippPriceDifference, commodityCountingIsAboveTwo ) => {
  const { percentageAboveIpp, amountAboveIpp, isAboveIpp } = ippPriceDifference;
  if (isAboveIpp) {
    if (!commodityCountingIsAboveTwo) {
      return(
        <MessageWarning>
          The most cost-effective option has not been chosen. Purchase is{" "}
          <span className={style.valuesAboveIpp}>{percentageAboveIpp}%</span> {" above IPP "}
          <span className={style.valuesAboveIpp}>(US$ {amountAboveIpp})</span>.
          <div>You have selected 2 or less commodity Prices, please justify the reason </div>
        </MessageWarning>
      )
    }else {
      return(
        <MessageWarning>
          The most cost-effective option has not been chosen. Purchase is{" "}
          <span className={style.valuesAboveIpp}>{percentageAboveIpp}%</span> {" above IPP "}
          <span className={style.valuesAboveIpp}>(US$ {amountAboveIpp})</span>.
        </MessageWarning>  
      )
    };
  } else {
    if (!commodityCountingIsAboveTwo) {
      return (
        <MessageWarning>
          You have selected 2 or less commodity Prices, please justify the reason
        </MessageWarning>
      )
    } else {
      return(
        <MessageInformation>
          The cost-effective solution has been chosen. There is no need for justification.
        </MessageInformation>
      )
    };
  };
};

export const disableJustification = (commodityCounting, ippComparison) => {
  if (ippComparison.isAboveIpp) {
    return false
  }
  if (!commodityCounting) {
    return false
  }
  return true
};

// The Justification component allows user to select a specific commodity from a list
const Justification = ({ isVisible, onClickNext, onClickPrevious, onClickDraft, ippComparison, commodityCounting, errorMessageJustification }) => {
  // Disable justification if it is not needed (the best option was chosen)
  const isDisabled = disableJustification(commodityCounting, ippComparison)

  return (
    <ShowHideArea isVisible={isVisible}>
      <Module
        title="Step 6/7 Justification"
        footer={
          <ButtonsWrapper>
            <Button kind="secondary" onClick={onClickDraft}>Save Draft</Button>
            <Button kind="secondary" onClick={onClickPrevious}>
              Previous
            </Button>
            <Button onClick={onClickNext}>Continue</Button>
          </ButtonsWrapper>
        }
      >
        {showMessage(ippComparison, commodityCounting)}
        {displayErrorMessage(errorMessageJustification)}
        <CheckboxWrapper title="Select a justification" isDisabled={isDisabled}>
          {JUSTIFICATION_OPTIONS.map(option => (
            <Field
              component={Checkbox}
              key={option.id}
              name={option.id}
              id={option.id}
              isDisabled={isDisabled}
              text={option.text}
            />
          ))}
        </CheckboxWrapper>
        <FormWrapper>
          <Upload name="attachments" label="Attachments" />
        </FormWrapper>
      </Module>
    </ShowHideArea>
  );
};

// propTypes for the Justification component
Justification.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClickNext: PropTypes.func.isRequired,
  onClickDraft: PropTypes.func.isRequired,
  onClickPrevious: PropTypes.func.isRequired,
  ippComparison: PropTypes.shape({
    isAboveIpp: PropTypes.bool,
    amountAboveIpp: PropTypes.string,
    percentageAboveIppe: PropTypes.string
  }).isRequired,
  errorMessageJustification: PropTypes.string.isRequired
};

Justification.defaultProps = {
  ippComparison: {
    isAboveIpp: false
  }
};

export default reduxForm({ form: "ipfDetailJustification", enableReinitialize: true })(Justification);
