import React from "react";
import { Field } from "redux-form";
import PropTypes from "prop-types";
import { iconWfpHumResourcingPos } from "@wfp/icons";

import Icon, { ClockIcon } from "../components/Icon";
import Radio from "../components/Radio";
import Tag from "../components/Tag";

import style from "./style.scss";
import {formatNumber} from "./index";
import {
  PRICE_TYPES,
} from "@constants";

// The sources list table used by many components
export const PurchaseOptionsFromIpfTable = ({ ipf, withRadio, preferredOnly }) => {
  const data = preferredOnly? ipf.purchase_options.filter(item => item.preferred) : ipf.purchase_options;
  return (
    <>
      {data.map(purchaseOption => (
        <div key={purchaseOption.id} className={style.purchaseOption}>
          {withRadio ? (
            <div>
              <Field
                component={Radio}
                name="options"
                id={String(purchaseOption.id)}
                value={String(purchaseOption.id)}
                checked={purchaseOption.preferred}
                text=""
              />
            </div>
          ) : (
            <div/>
          )}
          <div className={style.purchaseOptionRight}>
            <h4 className={style.title}>Delivery</h4>
            <div className={style.header}>
              <div>
                <p>Named place</p>
                <span>{`${purchaseOption.incoterm_place.name} (${purchaseOption.incoterm_place.country.name})`}</span>
              </div>
              <div>
                <p>Incoterm</p>
                <span>{purchaseOption.incoterm.code}</span>
              </div>
              <div>
                <p>Source</p>
                <span>
                  <Tag>{PRICE_TYPES.find(source => purchaseOption.source_of_supply === source.value).description}</Tag>
                </span>
              </div>
              <div>
                <p>Packaging</p>
                <span>{purchaseOption.packaging_type.description}</span>
              </div>
            </div>
            <div className={style.table}>
              <div>
                <div className={style.column}>
                  <div>
                    <h6 className={style.title}>Lead Times (days)</h6>
                    <table>
                      <tbody>
                        <tr>
                          <td>Technical</td>
                          <td>{purchaseOption.processing_time}</td>
                        </tr>
                        <tr>
                          <td>Shipping/ Discharge</td>
                          <td>{purchaseOption.port_time}</td>
                        </tr>
                        <tr>
                          <td>Landside</td>
                          <td>{purchaseOption.overland_time}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <table>
                      <tbody>
                        <tr>
                          <td>Total time</td>
                          <td>{purchaseOption.total_time}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div>
                <div className={style.column}>
                  <div>
                    <h6 className={style.title}>Costs (US$/Mt)</h6>
                    <table>
                      <tbody>
                        <tr>
                          <td>Commodity</td>
                          <td>{formatNumber(purchaseOption.commodity_cost)}</td>
                        </tr>
                        <tr>
                          <td>Shipping rate</td>
                          <td>{formatNumber(purchaseOption.ocean_transportation_cost)}</td>
                        </tr>
                        <tr>
                          <td>Port/ Handling/ Other</td>
                          <td>{formatNumber(purchaseOption.port_and_handling_cost)}</td>
                        </tr>
                        <tr>
                          <td>Landside rate</td>
                          <td>{formatNumber(purchaseOption.overland_transportation_cost)}</td>
                        </tr>
                        <tr>
                          <td>Quantity & Quality</td>
                          <td>{formatNumber(purchaseOption.quality_and_quantity)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <table>
                      <tbody>
                        <tr>
                          <td>Total Cost/Mt</td>
                          <td>{formatNumber(purchaseOption.cost_per_mt)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div>
                <div className={style.column}>
                  <div>
                    <h6 className={style.titleRight}>Total IPF Cost (US$)</h6>
                  </div>
                  <div>
                    {ipf.best_costs_options.indexOf(purchaseOption.id) > -1 ? (
                      <div className={style.icon}>
                        Cheapest option
                        <Icon
                          icon={iconWfpHumResourcingPos}
                          width="18"
                          height="18"
                          style={{padding: "0rem 0rem 0rem 0.4rem"}}
                        />
                      </div>
                    ) : (
                      undefined
                    )}
                    {ipf.best_time_options.indexOf(purchaseOption.id) > -1 ? (
                      <div className={style.icon}>
                        Fastest option
                        <ClockIcon name="light" width="18" height="18" style={{padding: "0rem 0rem 0rem 0.4rem"}}/>
                      </div>
                    ) : (
                      undefined
                    )}
                    <span className={style.totalCost}>{formatNumber(purchaseOption.total_costs)}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

// propTypes for the PurchaseOptionsTable component
PurchaseOptionsFromIpfTable.propTypes = {
  ipf: PropTypes.object.isRequired,
  withRadio: PropTypes.bool,
  preferredOnly: PropTypes.bool
};

// defaultProps for the PurchaseOptionsTable component
PurchaseOptionsFromIpfTable.defaultProps = {
  withRadio: false
};
