import React from "react";
import PropTypes from "prop-types";
import { fieldInputPropTypes } from "redux-form";
import { RadioButton } from "@wfp/ui";

import { displayErrorHelpText } from "../../utils";

import style from "./style.scss";


// A wrapper of the Radio component form WFP UI Kit
const Radio = ({ id, label, text, errorMessage, input: {name, value, onChange} }) => (
  console.log("Il valore di ID vale: " + id),
  console.log("--------------"),
  console.log("Il valore di value vale: " + value),
  <div className="wfp--form-item">
    {
      label
        ? <label htmlFor={id} className="wfp--label">{label}</label>
        : ""
    }
    <div className={style.wrapper}>
      <RadioButton
        name={name}
        checked={value === id}
        onChange={onChange}
        labelText={text}
        id={id}
        value={id}
      />
    </div>
    {displayErrorHelpText(errorMessage)}
  </div>
);

// propTypes for the Radio component
Radio.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  errorMessage: PropTypes.string,
  text: PropTypes.string.isRequired,
};

// defaultProps for the Radio component
Radio.defaultProps = {
  label: "",
  errorMessage: ""
};

export default Radio;
