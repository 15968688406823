import React, { Component } from "react";
import PropTypes from "prop-types";
import { Checkbox as WFPCheckbox } from "@wfp/ui";

import CheckboxWrapper from "@components/CheckboxWrapper";
import { Field } from "redux-form";

import style from "./style.scss";
import {displayErrorHelpText} from "../../utils";

class CheckboxGroup extends Component {
  field({ input, meta, options, isDisabled, errorMessage }) {
    const { name, onChange } = input;
    const { touched, error } = meta;
    const inputValue = input.value;

    const checkboxes =
      options.length &&
      options.map(({ label, value }, index) => {
        const handleChange = isNowChecked => {
          debugger;
          const arr = [...inputValue];
          if (isNowChecked) {
            arr.push(value);
          } else {
            arr.splice(arr.indexOf(value), 1);
          }
          return onChange(arr);
        };
        const checked = inputValue.includes(value);
        return (
          <div key={`${name}-${index}`} className={style.checkboxListElement}>
            <WFPCheckbox
              wrapperClassName={style.single}
              key={`${name}-${index}`}
              id={`${name}-${index}`}
              labelText={label}
              name={`${name}[${index}]`}
              value={value}
              checked={checked}
              disabled={isDisabled}
              onChange={ev => handleChange(ev.currentTarget.checked)}
            />
          </div>
        );
      });

    return (
      <CheckboxWrapper title={this.props.title} isDisabled={isDisabled}>
        <div className={style.checkboxList}>{checkboxes}</div>
        {touched && error && <p className="error">{error}</p>}
        <div>{displayErrorHelpText(errorMessage)}</div>
      </CheckboxWrapper>
    );
  }

  render() {
    return <Field {...this.props} type="checkbox" component={this.field.bind(this)} />;
  }
}

// propTypes for the CheckboxGroup component
CheckboxGroup.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired
    })
  ).isRequired,
  isDisabled: PropTypes.bool
};

CheckboxGroup.defaultProps = {
  isDisabled: false
};
export default CheckboxGroup;
